import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppNavbarBrand, AppSidebarToggler, AppHeaderDropdown } from '@coreui/react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown, Label,
} from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setStatusAuth, authDataLogout } from '../actions/user';
import { getDataWhiteLabel, setDefaultWhiteLabel } from '../actions/branch';
import { localGet, localRemove, localSet } from '../lib/session';
import Logo from '../assets/imgs/pay2b Logo_pay2b-07.png';
import { AUTH_TOKEN, IS_LOGGED, WHITELABEL } from '../actionTypes/auth';

class DefaultHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.logoutHandle = this.logoutHandle.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = {
      DropdownText: 'Todas as Lojas',
      DropdownValue: 'ALL',
      Width: window.innerWidth,
    };
    const p = this.props;
    p.getDataWhiteLabel();
  }

  componentDidMount() {
    const p = this.props;
    const whitelabel = localGet(WHITELABEL);

    if (whitelabel && whitelabel.value !== 'ALL') {
      p.setDefaultWhiteLabel(whitelabel.value);
      this.setState({ DropdownValue: whitelabel.value, DropdownText: whitelabel.name });
    }
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  changeValue(e) {
    const p = this.props;

    this.setState({
      DropdownText: e.currentTarget.textContent,
      DropdownValue: e.currentTarget.getAttribute('value'),
    });

    localSet('P2B_PORTAL_WHITELABEL', { value: e.currentTarget.getAttribute('value'), name: e.currentTarget.textContent });

    p.setDefaultWhiteLabel(e.currentTarget.getAttribute('value'));
  }

  logoutHandle(event) {
    event.preventDefault();

    const p = this.props;
    const authData = localGet(AUTH_TOKEN);

    localRemove(AUTH_TOKEN);
    localRemove('P2B_PORTAL_WHITELABEL');
    localRemove(IS_LOGGED);
    if (authData && authData.AccessToken) {
      p.authDataLogout(authData.AccessToken);
    }
    p.setStatusAuth();
  }

  render() {
    const { DropdownText, DropdownValue, Width } = this.state;
    const { authUser, listWhitelabel } = this.props;
    const isMobile = Width < 999;

    if (!authUser) {
      return <Redirect to={{ pathname: '/login' }} />;
    }

    if (listWhitelabel && listWhitelabel.state !== false) {
      return null;
    }

    if (!listWhitelabel || !listWhitelabel.data) {
      return null;
    }

    const model = authUser.data;
    const modelBranch = listWhitelabel.data;

    return (
      <>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: Logo,
            width: 100,
            height: 'auto',
            alt: 'Logo',
          }}
          minimized={{
            src: Logo,
            width: 55,
            height: 'auto',
            alt: 'Logo',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="d-md-down-none" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav key={DropdownValue} className="d-flex align-items-center">
              <Label className="text-truncate mb-0" style={{ maxWidth: 175, cursor: 'pointer' }}>
                {DropdownText}
              </Label>
              <i className="fa fa-angle-down ml-2" />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto', maxWidth: 250 }}>
              <DropdownItem
                key="ALL"
                onClick={this.changeValue}
                value="ALL"
              >
                Todas as Lojas
              </DropdownItem>
              {modelBranch.length >= 0
                ? modelBranch.map((obj) => (
                  <DropdownItem
                    key={obj.Uuid}
                    onClick={this.changeValue}
                    value={obj.Uuid}
                  >
                    <Label
                      className="text-truncate m-0 w-100"
                      title={obj.FantasyName}
                    >
                      {obj.FantasyName}
                    </Label>
                  </DropdownItem>
                ), this) : []}
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown direction="down">
            <DropdownToggle nav>
              <span style={{ marginRight: 5 }}>
                <i className="fa fa-user" />
              </span>
              <span style={{
                width: isMobile ? 100 : 'auto', height: 20, overflow: 'hidden', display: 'inline-flex',
              }}
              >
                {' '}
                {model && model.Name ? model.Name : 'Admin'}
                {' '}
              </span>
              <span>
                <i className="fa fa-angle-down ml-2" />
              </span>
            </DropdownToggle>
            <DropdownMenu right style={{ right: '0', minWidth: 'fit-content' }}>
              <DropdownItem onClick={this.logoutHandle} style={{ minWidth: 'auto' }}>
                <i className="fa fa-arrow-left" />
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </>
    );
  }
}

DefaultHeader.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  setStatusAuth: PropTypes.func.isRequired,
  authDataLogout: PropTypes.func.isRequired,
  getDataWhiteLabel: PropTypes.func.isRequired,
  setDefaultWhiteLabel: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.shape({
      Name: PropTypes.string,
    }),
    state: PropTypes.bool,
  }).isRequired,
  listWhitelabel: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.objectOf),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setStatusAuth,
    authDataLogout,
    getDataWhiteLabel,
    setDefaultWhiteLabel,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
  listWhitelabel: state.branchState.listWhitelabel,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultHeader));
